<template>
  <div class="cooperation">
    <header class="cooperation_head">
      <img src="@/assets/cooperation/head.png" class="head_img" />
    </header>
    <main class="cooperation_main">
      <div class="item_cooperation">
        <div class="introduce_box">
          <img src="@/assets/select_idx.png" class="item_icon" />
          <div class="item_title">关于隆福</div>
          <div class="item_title1">PROJECT INTRODUCTION</div>
          <img src="@/assets/cooperation/introduce.png" class="img_css" />
          <div class="introduce_text">
            <div class="introduce_text_title">北京老城复兴金名片 世界级文化艺术消费目的地</div>
            <div class="introduce_text_conent">
              &#12288;&#12288;隆福寺项目分三期进行改造。项目一期于2019年8月29日正式开业运营，建筑面积约7.5万平方米，包含隆福大厦和隆福寺北里，已成为汇集共享办公、美术馆、精品书店、特色餐饮等多种业态于一体的文化创意园区，独具古都风韵的隆福文化中心也成为举办高品质文化艺术活动的首选地，更潮流、更年轻、更时尚，成为隆福寺的新标签。
              <br/>&#12288;&#12288;2020年，隆福寺被列入北京市推进全国文化中心建设中长期规划（2019年-2035年）的“文化商圈”计划、获评北京市级文化产业园区、北京十大商业品牌—网红打卡地及北京文化消费新地标等。
              <br/>&#12288;&#12288;项目二期总建筑面积约10.17万平方米，包括隆福寺南坊、隆福寺东院、长虹影院以及公共空间街区提升改造项目，将通过联动艺展和演出，结合艺术与潮流形式联合共创，建设数字化智慧商圈，打造具有北京特色的街区式文化商业。
              <br/>&#12288;&#12288;隆福广场总建筑面积约4.45万平方米，地上4层（东区局部5层），地下2层，重新命名为“隆福寺南坊”。
              <br/>&#12288;&#12288;长虹影城总建筑面积约7200平方米，地上3层，地下3层。
              <br/>&#12288;&#12288;地铁东四站织补项目总占地面积约1.2万平方米，规划建筑面积5.35万平方米，为项目唯一的新建地块。重新命名为“隆福寺东院”。
              <br/>&#12288;&#12288;项目三期为风貌区改造，将打造环境幽美、功能复合的四合文化慢里。
            </div>
          </div>
        </div>
        <div class="introduce_box clear_padding">
          <img src="@/assets/select_idx.png" class="item_icon" />
          <div class="item_title">项目定位</div>
          <div class="item_title1">PROJECT ORIENTATION</div>
        </div>
      </div>

      <div class="postion_boxs">
        <div class="postion_text">
            <div class="mb10">传统文化与现代文明-交相辉映</div>
            <div>中华文化与世界文明-协调共融</div>
        </div>
        <div class="postion_img_boxs">
            <img src="@/assets/cooperation/postion_img.png" class="postion_img">
        </div>
      </div>

      <div class="postion_img_inner">
        <div class="img_inner">
            <div class="item_img">
                <img src="@/assets/cooperation/postion1.png" class="img_css">
                <div class="item_text">北里</div>
            </div>
            <div class="item_img">
                <img src="@/assets/cooperation/postion2.png" class="img_css">
                <div class="item_text">隆福大厦</div>
            </div>
        </div>
        <div class="img_inner">
            <div class="item_img">
                <img src="@/assets/cooperation/Nanfang.png" class="img_css">
                <div class="item_text">南坊</div>
            </div>
            <div class="item_img">
                <img src="@/assets/cooperation/Hospital.png" class="img_css">
                <div class="item_text">东院</div>
            </div>
        </div>
      </div>
    </main>
    <footer class="cooperation_footer">
        <div class="item_foot">
            <div>联系我们</div>
            <div class="foot_phone">010-84036542</div>
        </div>
        <div class="item_foot">
          <div style="margin-left: 100px;">电子邮箱</div>
          <div style="margin-left: 100px;" class="foot_phone">business@longfusi.com.cn</div>
        </div>
<!--        <div class="item_foot display_flex">-->
<!--            <div class="nav_boxs">-->
<!--                <div class="nav_text">导航至隆福</div>-->
<!--                <img src="@/assets/cooperation/navigation.png" class="navigation_img">-->
<!--            </div>-->
<!--        </div>-->
    </footer>
  </div>
</template>

<script setup>
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 1536px) {
  .item_cooperation,.introduce_box,.postion_img_inner{
    width: 1200px !important;
  }
  .item_title,.item_title1,.introduce_text_title,.postion_text,.item_text,.item_foot{
    font-size: 22px !important;
  }
  .introduce_text_conent,.foot_phone{
    font-size: 14px !important;
    line-height:30px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .item_cooperation,.introduce_box,.postion_img_inner{
    width: 950px !important;
  }
  .item_title,.item_title1,.introduce_text_title,.postion_text,.item_text,.item_foot{
    font-size: 20px !important;
  }
  .introduce_text_conent,.foot_phone{
    font-size: 12px !important;
    line-height:24px !important;
  }
}
@media only screen and (max-width: 960px) {
  .item_cooperation,.introduce_box,.postion_img_inner{
    width: 850px !important;
  }
}
@media only screen and (max-width: 768px) {
  .item_cooperation,.introduce_box,.postion_img_inner{
    width: 680px !important;
  }
}
@media only screen and (max-width: 640px) {
  .item_cooperation,.introduce_box,.postion_img_inner{
    width: 560px !important;
  }
}
//@media only screen and (min-width: 2133px) {
//  .item_cooperation,.introduce_box,.postion_img_inner{
//    width: 1600px !important;
//  }
//  .item_title,.item_title1,.introduce_text_title,.postion_text,.item_text,.item_foot{
//    font-size: 26px !important;
//  }
//  .introduce_text_conent,.foot_phone{
//    font-size: 16px !important;
//    line-height:30px !important;
//  }
//}
//@media only screen and (min-width: 2400px) {
//  .item_cooperation,.introduce_box,.postion_img_inner{
//    width: 1800px !important;
//  }
//  .item_title,.item_title1,.introduce_text_title,.postion_text,.item_text,.item_foot{
//    font-size: 26px !important;
//  }
//  .introduce_text_conent,.foot_phone{
//    font-size: 18px !important;
//    line-height:36px !important;
//  }
//}
//@media only screen and (min-width: 2560px) {
//  .item_cooperation,.introduce_box,.postion_img_inner{
//    width: 2000px !important;
//  }
//  .item_title,.item_title1,.introduce_text_title,.postion_text,.item_text,.item_foot{
//    font-size: 28px !important;
//  }
//  .introduce_text_conent,.foot_phone{
//    font-size: 20px !important;
//    line-height:40px !important;
//  }
//}
//@media only screen and (min-width: 2880px) {
//  .item_cooperation,.introduce_box,.postion_img_inner{
//    width: 2250px !important;
//  }
//  .item_title,.item_title1,.introduce_text_title,.postion_text,.item_text,.item_foot{
//    font-size: 38px !important;
//  }
//  .introduce_text_conent,.foot_phone{
//    font-size: 26px !important;
//    line-height:50px !important;
//  }
//}
//@media only screen and (min-width: 3840px) {
//  .item_cooperation,.introduce_box,.postion_img_inner{
//    width: 2950px !important;
//  }
//  .item_title,.item_title1,.introduce_text_title,.postion_text,.item_text,.item_foot{
//    font-size: 48px !important;
//  }
//  .introduce_text_conent,.foot_phone{
//    font-size: 38px !important;
//    line-height: 60px !important;
//  }
//}
//@media only screen and (min-width: 5760px) {
//  .item_cooperation,.introduce_box,.postion_img_inner{
//    width: 4550px !important;
//  }
//  .item_title,.item_title1,.introduce_text_title,.postion_text,.item_text,.item_foot{
//    font-size: 60px !important;
//  }
//  .introduce_text_conent,.foot_phone{
//    font-size: 50px !important;
//    line-height: 100px !important;
//  }
//}
//@media only screen and (min-width: 7680px) {
//  .item_cooperation,.introduce_box,.postion_img_inner{
//    width: 5900px !important;
//  }
//  .item_title,.item_title1,.introduce_text_title,.postion_text,.item_text,.item_foot{
//    font-size: 68px !important;
//  }
//  .introduce_text_conent,.foot_phone{
//    font-size: 56px !important;
//    line-height: 120px !important;
//  }
//}
</style>
<style lang="scss" scoped>
.cooperation {
  z-index: 1;
  max-width: 1920px;
  margin: 0 auto;
  .img_css {
    width: 100%;
    display: flex;
  }
  .cooperation_head {
    // border: 30px solid #ffda00;

    .head_img {
      width: 100%;
      display: flex;
    }
  }

  .cooperation_main {
    font-family: PingFang SC;

    .item_cooperation {
      width: 1400px;
      margin: 0 auto;
        // 项目介绍
      .introduce_box {
        text-align: center;
        width: 1400px;
        margin: 0 auto;
        margin-top: 80px;
        // padding: 0 260px;

        .item_icon {
          width: 41px;
          height: 28px;
        }

        .item_title {
          margin-top: 10px;
          font-size: 40px;
          font-weight: 400;
          color: #8c857b;
        }
        .item_title1 {
          font-size: 40px;
          font-family: Gotham;
          font-weight: 400;
          color: #eeeeee;
          margin-bottom: 49px;
        }

        .introduce_text {
            margin-top: 2px;
            text-align: left;
            color: #FFFFFF;
            padding: 41px 40px 37px 40px;
            background: #8C857B;

            .introduce_text_title {
                 font-size: 36px;
                font-weight: 300;
            }

            .introduce_text_conent {
                font-size: 18px;
                font-weight: 100;
                color: #FFFFFF;
                line-height: 36px;
                font-family: PingFang SC;
            }
        }
      }
      }
    .postion_img_inner {
        width: 1400px;
        margin: 0 auto;
        // padding: 50px 260px 80px 260px;
        padding: 50px 0 80px;
        .img_inner {
            display: flex;
            margin-top: 3px;
            .item_img {
                flex: 1;
                position: relative;

                &:nth-child(2n) {
                    margin-left: 2px;
                }

                .item_text {
                    position: absolute;
                    width: 100%;
                    height: 80px;
                    background: rgba(140, 133, 123, 0.9);
                    bottom: 0;
                    text-align: center;
                    line-height: 80px;
                    font-size: 30px;
                    font-weight: 300;
                    color: #FFFFFF;
                }
            }
        }
    }
  }



  // 项目定位
  .postion_boxs {
    width: 100%;
    display: flex;

    .postion_text {
        width: 50%;
        flex-shrink: 0;
        background: #8C857B;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 36px;
        font-weight: 300;
        color: #FFFFFF;

        .mb10 {
            margin-bottom: 10px;
        }
    }

    .postion_img_boxs {
        flex: 1;

        .postion_img {
            width: 100%;
        }
    }
}

  .cooperation_footer {
    margin-top: 80px;
    // height: 203px;
    background: #8C857B;
    padding: 60px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .item_foot {
        // flex: 1;
        width: 509px;
        // text-align: center;
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 100;
        color: #FFFFFF;

        &:last-child {
            border-left: 1px solid #FFFFFF;
        }

        .foot_phone {
            margin-top: 20px;
            font-size: 36px;
            font-weight: 300;
            color: #FFFFFF;
        }

        &.display_flex {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .nav_boxs {
            border: 1px solid #FFFFFF;
             padding: 27px 47px;
            display: flex;
            align-items: center;

            .navigation_img {
                margin-left: 19px;
                width: 26px;
                height: 26px;
            }
        }
    }
  }

  .clear_padding {
    padding: 0 !important;
  }
}
</style>
